// DocsListByYear.js
import React from 'react';
import styles from './docs.module.css';
import DownloadPDF from '../components/evento/Download';

const DocsListByYear = ({ year, documents }) => {
  return (
    <li className={styles.docs_background}>
      <div className={styles.docs_container}>
        {documents.map((doc, index) => (
          <DownloadPDF
            key={index}
            pdfFileName={doc.fileName}
            pdfFilePath={`/docs/${year}/`}
          />
        ))}
      </div>
    </li>
  );
};

export default DocsListByYear;
