//Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import Container from './Container';
import { FaUser } from 'react-icons/fa';
import styles from './Navbar.module.css';
import logo from '../../img/logo/Logo_Viva_Null.png';
import ThemeButton from '../evento/ThemeButton';

function Navbar() {
  return (
    <nav className={styles.navbar}>
      <Container>
        <ul className={styles.list}>
          <li className={styles.logo}>
            <Link to="/">
              <img src={logo} alt="Fonte Viva" className={styles.logo} />
            </Link>
          </li>

          <li className={styles.item}><Link to="/">Início</Link></li>
          <li className={styles.item}><Link to="/transparencia">Transparência</Link></li>
          <li className={styles.item}><Link to="/historia">História</Link></li>
          <li className={styles.item}><Link to="/noticias">Notícias</Link></li>
          <li className={styles.item}><Link to="/eventos">Eventos</Link></li>
          <li className={styles.item}><Link to="/contato">Contato</Link></li>

          <li className={styles.login_button}>
            <Link to="/entrar"><FaUser /> Entrar</Link>
          </li>

          <li className={styles.theme_button}>
            <ThemeButton />
          </li>
        </ul>
      </Container>
    </nav>
  );
}

export default Navbar;
