//ExpansiveList.js
import styles from './ExpansiveList.module.css';
import React, { Component } from 'react';
import ItemList from './ItemList';
import FunctionDocs_2021 from '../../docs/FunctionDocs_2021';
import FunctionDocs_2022 from '../../docs/FunctionDocs_2022';
import FunctionDocs_2023 from '../../docs/FunctionDocs_2023';
import {NameDocs_2021, NameDocs_2022, NameDocs_2023} from '../../docs/NameDocs';

class ExpansiveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itens: [
        // eslint-disable-next-line react/jsx-pascal-case
        { id: 1, titulo: <NameDocs_2021 />, conteudo: <FunctionDocs_2021 /> },
        // eslint-disable-next-line react/jsx-pascal-case
        { id: 2, titulo: <NameDocs_2022 />, conteudo: <FunctionDocs_2022 /> },
        // eslint-disable-next-line react/jsx-pascal-case
        { id: 3, titulo: <NameDocs_2023 />, conteudo: <FunctionDocs_2023 /> },
      ],
      itensExpandidos: [],
    };
  }

  handleExpandirItem = (id) => {
    this.setState((prevState) => {
      const itensExpandidos = prevState.itensExpandidos.includes(id)
        ? prevState.itensExpandidos.filter((item) => item !== id)
        : [...prevState.itensExpandidos, id];

      return { itensExpandidos };
    });
  };

  render() {
    return (
      <div>
        <ul>
          {this.state.itens.map((item) => (
            <div key={item.id} className={styles.itemCard}>
              <ItemList
                item={item}
                expandido={this.state.itensExpandidos.includes(item.id)}
                onExpandirItem={this.handleExpandirItem}
              />
            </div>
          ))}
        </ul>
      </div>
    );
  }
}

export default ExpansiveList;