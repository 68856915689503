//ItemList.js
import styles from './ItemList.module.css';
import React from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

const ItemList = ({ item, expandido, onExpandirItem }) => {
  const tooltipText = expandido ? 'Clique para recolher' : 'Clique para expandir';

  return (
    <li className={styles.list}>
      <div className={styles.card}>
        <button
          className={styles.expand_area}
          onClick={() => onExpandirItem(item.id)}
          title={tooltipText}
        >
          {expandido ? (
            <MdExpandLess className={styles.expand_icon} />
          ) : (
            <MdExpandMore className={styles.expand_icon} />
          )}
          <span className={styles.year_label}>{item.titulo}</span>
        </button>

        {expandido && (
          <div className={styles.content}>
            <AiFillCaretDown className={styles.iconColor} />
            {item.conteudo}
          </div>
        )}
      </div>
    </li>
  );
};

export default ItemList;
