import styles from './History.module.css'
import {Link} from 'react-router-dom'
import { FaHome } from "react-icons/fa";

function History() {
    return(

        <div>
            <div className={styles.path_border}>
                <li className={styles.path}>
                    <Link to="/"><FaHome/>Inicio</Link><span> / História</span>
                </li>
            </div>

            <li className={styles.history_background}>
                <div className={styles.history_container}>
                    <h1>
                        HISTÓRIA DE CRIAÇÃO
                    </h1>
                    <p>
                        A Associação Beneficente Fonte Viva, é uma entidade sem fins lucrativos mantenedora do Centro de Educação Infantil Fonte Viva, criado há mais de tinta (30) anos pela Sr. Margarida de Oliveira Moya como Creche Irmã Ciana, que idealizou esse projeto e administrou com muita determinação e coragem, atendia crianças de zero a seis anos, pertencentes a famílias de baixa renda com o intuito de acolher, cuidar e dar assistência às famílias.
                    </p> 
                    <p>
                        Hoje vive uma nova realidade e é administrada pelo Sr. Flávio Augusto de Oliveira, envolvendo uma equipe pedagógica nas atividades desenvolvidas, nos permitindo, acolher, cuidar e educar crianças de zero a três anos onze meses e vinte e nove dias, sob a supervisão da Secretaria da Educação.
                    </p>
                    <p>
                        A entidade atende 50 crianças com o compromisso de desenvolver um grande trabalho no município de Votuporanga, procura oferecer diversas possibilidades educacionais para que a criança desenvolva relações afetivas, autoestima, já que a educação infantil é um espaço de inserção e desenvolvimento psicológico, motor, físico, intelectual, social, considerando que o ambiente escolar é um espaço de trocas afetivas e contribui para a construção da cidadania.
                    </p>
                    <p>
                        Todo trabalho é fundamentado no Projeto Político Pedagógico que tem por objetivo reunir e explicar os principais norteadores da instituição e os fundamentos que balizam a conduta dos que nela trabalham.
                    </p>
                    <p>
                        O trabalho educativo pode assim criar condições para as crianças conhecerem, descobrirem, e dar novos significados, valores, ideias, costumes e papeis sociais. 
                    </p>
                    <p>
                        O papel da educação infantil tem por finalidade cuidar da criança em espaço formal, contemplando a alimentação, a limpeza e o lazer (brincar). Também é seu papel educar, sempre respeitando o caráter lúdico das atividades, com ênfase no desenvolvimento integral da criança.
                    </p>
                    <p>
                        Por um documento de gestão democrática será objeto de permanente reflexão coletiva no que se refere aos princípios e valores que fundamentam as finalidades da instituição, a sua estrutura organizacional e instâncias de decisão; as relações entre a comunidade escolar; a organização administrativa e pedagógica; os conteúdos curriculares; os procedimentos didáticos; as estratégias de avaliação e as atividades culturais.
                    </p>
                    <p>
                        As três dimensões do Projeto Político Pedagógico têm por finalidade não somente pensar, mas agir coletivamente, o envolvimento de todos beneficiará alunos, profissionais da educação e gestores, bem como a sociedade num todo.
                    </p>
                    <p>
                        Para nortear este projeto teremos como base os artigo 22 e 29 da Lei de Diretrizes e Bases da Educação nº9394/96.
                    </p>
                    <p>
                        - Art. 22. A educação Básica tem por finalidade desenvolver o educando, assegurar-lhe a formação comum indispensável para o exercício da cidadania e fornecer-lhe meios para progredir no trabalho e em estudos posteriores.
                    </p>
                    <p>
                        - Art. 29. A educação Infantil, primeira etapa da educação básica, tem por finalidade o desenvolvimento integral da criança até cinco anos de idade, em seus aspectos físico, psicológico, intelectual e social, complementando a ação da família e da comunidade.
                    </p>
                    <p>
                        Dentro deste contexto o CEI Fonte Viva, se compromete a não somente dar cuidados necessários ao desenvolvimento biológico das crianças, mas oportunizar um espaço, um atendimento e um processo de aprendizagem que juntos preparem as mesmas para as próximas etapas da vida.
                    </p>
                        
                </div>
            </li>
        </div>
        
    )

}

export default History