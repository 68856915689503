//Footer.js
import React from 'react';
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import {
  FaFacebook,
  FaInstagram,
  FaUserClock,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaEnvelope,
} from 'react-icons/fa';
import styles from './Footer.module.css';

function Footer({ darkMode }) {
  return (
    <footer className={darkMode ? styles.container_night : styles.container_light}>
      <div className={styles.container}>
        <div className={styles.list_left}>
          <ul>
            <li className={styles.social_container}>
              <div>
                Centro de Educação Infantil Fonte Viva
              </div>
              <div>
                <a
                  href="https://www.facebook.com/profile.php?id=100071797126738&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className={styles.social_icon} />
                </a>
                <a
                  href="https://www.instagram.com/cei.fonteviva"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram className={styles.social_icon} />
                </a>
              </div>
            </li>
          </ul>
        </div>

        <div className={styles.list_right}>
          <ul>
            <li className={styles.information_container}>
              <FaUserClock className={styles.item_icon} />
              Atendimento ao público das 07h às 17h, de segunda a sexta-feira
            </li>
            <li className={styles.information_container}>
              <FaMapMarkedAlt className={styles.item_icon} />
              Rua Vicente Castrequini, nº 3717 - Jardim Planalto - CEP: 15501-105
            </li>
            <li className={styles.information_container}>
              <FaPhoneAlt className={styles.item_icon} />
              (17) 99607-7665
            </li>
            <li className={styles.information_container}>
              <FaEnvelope className={styles.item_icon} />
              fontevivaeducacaoinfantil@gmail.com
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.copyright_container}>
        <span>
          <AiOutlineCopyrightCircle />
          &nbsp;Copyright: FonteViva 2023-2024
        </span>
      </div>
    </footer>
  );
}

export default Footer;
