//NameDocs.js
import styles from './NameDocs.module.css';
import { FaFolderOpen } from 'react-icons/fa';

export function NameDocs_2021() {
  return (
    <div className={styles.year_container}>
      <FaFolderOpen className={styles.icon} />
      <span className={styles.text}>Documentos 2021</span>
    </div>
  );
}

export function NameDocs_2022() {
  return (
    <div className={styles.year_container}>
      <FaFolderOpen className={styles.icon} />
      <span className={styles.text}>Documentos 2022</span>
    </div>
  );
}

export function NameDocs_2023() {
  return (
    <div className={styles.year_container}>
      <FaFolderOpen className={styles.icon} />
      <span className={styles.text}>Documentos 2023</span>
    </div>
  );
}
