import styles from './Download.module.css';
import React from 'react';
import { BiDownload } from 'react-icons/bi';
import { FcDownRight } from 'react-icons/fc';

const DownloadPDF = ({ pdfFileName, pdfFilePath }) => {
  const handleDownload = () => {
    const pdfUrl = process.env.PUBLIC_URL + pdfFilePath + pdfFileName;

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = pdfFileName;
    link.click();
  };

  return (
    <div className={styles.item}>
      <button className={styles.button} onClick={handleDownload}>
        <div className={styles.left}>
          <FcDownRight className={styles.icon} />
          <span className={styles.fileName}>{pdfFileName}</span>
        </div>
        <BiDownload className={styles.downloadIcon} />
      </button>
    </div>
  );
};

export default DownloadPDF;
