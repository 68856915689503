//RouletteBoardPartners.js
import React, { useState } from 'react';
import CardRenderBoardPartners from './CardRenderBoardPartners';
import styles from './RouletteBoardPartners.module.css'; // Importando o arquivo CSS
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const RouletteBoardPartners = () => {
  const initialCards = [
    { logo: require('../../img/parcerias/Logo_PrefVotu.png'), nome: '', link: 'https://www.votuporanga.sp.gov.br/' },
    { logo: require('../../img/parcerias/Logo_AngusHouse.png'), nome: '', link: 'https://www.instagram.com/angus.house/' },
    { logo: require('../../img/parcerias/Logo_VilleHotel.png'), nome: '', link: 'https://www.villehotelgramadao.com.br/' },
    { logo: require('../../img/parcerias/Logo_Unifev.png'), nome: '', link: 'https://www.unifev.edu.br/' },
    { logo: require('../../img/parcerias/Logo_Facchini.png'), nome: '', link: 'https://www.facchini.com.br/' },
    { logo: require('../../img/parcerias/Logo_Noroaco.png'), nome: '', link: 'https://noroaco.com/site/index.php' },
    // Adicione mais marcas conforme necessário
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const visibleCards = initialCards.slice(currentIndex, currentIndex + 4);

  const moveLeft = () => {
    setCurrentIndex(prevIndex => {
      if (prevIndex === 0) {
        return initialCards.length - 4;
      }
      return prevIndex - 1;
    });
  };

  const moveRight = () => {
    setCurrentIndex(prevIndex => {
      if (prevIndex === initialCards.length - 4) {
        return 0;
      }
      return prevIndex + 1;
    });
  };

  return (
    <div className={styles.roulette}>
      <button className={styles.buttonLeft} onClick={moveLeft}><FaAngleLeft/></button>
      <CardRenderBoardPartners cards={visibleCards} />
      <button className={styles.buttonRight} onClick={moveRight}><FaAngleRight/></button>
    </div>
  );
};

export default RouletteBoardPartners;