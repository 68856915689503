// FunctionDocs_2023.js
import React from 'react';
import DocsListByYear from './DocsListByYear';

const FunctionDocs_2023 = () => {
  const documents = [
    { fileName: 'Anexo RP-09 - Repasses ao Terceiro Setor - Termo de Ciência e de Notificação - Termo de Colaboração 001_2024.pdf' },
    { fileName: 'Anexo RP-09 - Repasses ao Terceiro Setor - Termo de Ciência e Notificação - Termo de Colaboração 023-2023.pdf' },
    { fileName: 'Ata de Eleição do Quadro de Dirigentes.pdf' },
    { fileName: 'Certidão de Negativa de Débitos - Estadual.pdf' },
    { fileName: 'Certidão de Negativa de Débitos - Federal.pdf' },
    { fileName: 'Certidão de Negativa de Débitos - Municipal.pdf' },
    { fileName: 'Estatuto da Associação Beneficente Fonte Viva.pdf' },
    { fileName: 'Plano de Trabalho - FUNDEB.pdf' },
    { fileName: 'Plano de Trabalho - PNAE.pdf' },
    { fileName: 'Relação Nominal Atualizada dos Dirigentes da Entidade.pdf' },
    { fileName: 'Termo Aditivo de Termo de Colaboração - PNAE.pdf' },
    { fileName: 'Termo de Colaboração 001-2024 FUNDEB.pdf' },
    { fileName: 'Termo de Colaboração 023_2023 PNAE.pdf' },
    { fileName: 'Termo de Colaboração 028_2023 FUNDEB.pdf' },
  ];

  return <DocsListByYear year="2023" documents={documents} />;
};

export default FunctionDocs_2023;
