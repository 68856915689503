//Transparency.js
import styles from './Transparency.module.css';
import { Link } from 'react-router-dom';
import React from 'react';
import { FaHome } from "react-icons/fa";
import TransparencyListExpansive from '../components/transparency/TransparencyExpansiveList';

function Transparency() {
  return (
    <div className={styles.transparency_background}>
      <div className={styles.path_border}>
        <div className={styles.path}>
          <Link to="/"><FaHome /> Início</Link>
          <span>/ Transparência</span>
        </div>
      </div>

      <div className={styles.transparency_container}>
        <h1 className={styles.title}>
          Portal da Transparência
        </h1>

        <div className={styles.cont_list_container}>
          <TransparencyListExpansive />
        </div>
      </div>
    </div>
  );
}

export default Transparency;
