//FunctionDocs_2021.js
import React from 'react';
import DocsListByYear from './DocsListByYear';

const FunctionDocs_2021 = () => {
  const documents = [
    { fileName: 'Ata de Assembleia Geral de Posse da Diretoria.pdf' },
    { fileName: 'Balancete de Verificação.pdf' },
    { fileName: 'Declaração Quanto a Economicidade.pdf' },
    { fileName: 'Demonstrativo Integral das Receitas e Despesas Estadual.pdf' },
    { fileName: 'Demonstrativo Integral das Receitas e Despesas Federal.pdf' },
    { fileName: 'Estatuto da Associação Beneficente Fonte Viva.pdf' },
    { fileName: 'Plano de Trabalho FUNDEB.pdf' },
    { fileName: 'Plano de Trabalho PNAE.pdf' },
    { fileName: 'Relação de Prestadores de Serviços.pdf' },
    { fileName: 'Relação Nominal de Dirigentes.pdf' },
    { fileName: 'Relação Prestadores PNAE.pdf' },
    { fileName: 'Relatório de Execução Financeira - Pagamentos.pdf' },
    { fileName: 'Retificação Plano de Trabalho 2021.pdf' },
    { fileName: 'Termo de Colaboração PNAE.pdf' },
    { fileName: 'Termo de Colaboração.pdf' },
    { fileName: 'Valores Repassados PNAE.pdf' },
    { fileName: 'Valores Repassados.pdf' },
  ];

  return <DocsListByYear year="2021" documents={documents} />;
};

export default FunctionDocs_2021;