//TransparencyExpansiveList.js
import React, { useState } from 'react';
import styles from './TransparencyExpansiveList.module.css';
import ExpansiveList from './ExpansiveList';
import RouletteBoard from './RouletteBoard';
import RouletteCollaborators from './RouletteCollaborators';
import RouletteBoardPartners from './RouletteBoardPartners';

const TransparencyListExpansive = () => {
  const [expandido, setExpandido] = useState({
    documentos: false,
    administracao: false,
    contabilidade: false,
    planoAcao: false,
    parcerias: false,
  });

  const handleToggleExpansao = (secao) => {
    setExpandido((prev) => ({
      ...Object.fromEntries(Object.keys(prev).map((key) => [key, false])),
      [secao]: !prev[secao],
    }));
  };

  const getButtonClass = (secao) =>
    expandido[secao] ? styles.selected_button : styles.button_layout;

  return (
    <div>
      <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
      <button className={`${styles.buttonClass} ${getButtonClass('documentos')}`} onClick={() => handleToggleExpansao('documentos')}>
        Documentos
        <span className={styles.tooltip}>
          {expandido.documentos ? 'Clique para recolher' : 'Clique para expandir'}
        </span>
      </button>

      <button className={`${styles.buttonClass} ${getButtonClass('administracao')}`} onClick={() => handleToggleExpansao('administracao')}>
        Administração
        <span className={styles.tooltip}>
          {expandido.administracao ? 'Clique para recolher' : 'Clique para expandir'}
        </span>
      </button>

      <button className={`${styles.buttonClass} ${getButtonClass('contabilidade')}`} onClick={() => handleToggleExpansao('contabilidade')}>
        Contabilidade
        <span className={styles.tooltip}>
          {expandido.contabilidade ? 'Clique para recolher' : 'Clique para expandir'}
        </span>
      </button>

      <button className={`${styles.buttonClass} ${getButtonClass('planoAcao')}`} onClick={() => handleToggleExpansao('planoAcao')}>
        Plano de Ação
        <span className={styles.tooltip}>
          {expandido.planoAcao ? 'Clique para recolher' : 'Clique para expandir'}
        </span>
      </button>

      <button className={`${styles.buttonClass} ${getButtonClass('parcerias')}`} onClick={() => handleToggleExpansao('parcerias')}>
        Parcerias
        <span className={styles.tooltip}>
          {expandido.parcerias ? 'Clique para recolher' : 'Clique para expandir'}
        </span>
      </button>
      </div>

      <div>
        {expandido.documentos && (
          <div className={styles.expansive_layout}>
            <h1 className={styles.title_docs}>Documentos</h1>
            <ExpansiveList />
          </div>
        )}

        {expandido.administracao && (
          <div className={styles.expansive_layout}>
            <h1 className={styles.title_docs}>Diretoria Eleita</h1>
            <p>2023 a 2025</p>
            <RouletteBoard />

            <h1 className={styles.title_docs}>Quadro de Funcionários</h1>
            <RouletteCollaborators />
          </div>
        )}

        {expandido.contabilidade && (
          <div className={styles.expansive_layout}>
            <h1 className={styles.title_docs}>Conteúdo Contábil</h1>
            {/* Inserir conteúdo futuramente */}
          </div>
        )}

        {expandido.planoAcao && (
          <div className={styles.expansive_layout}>
            <h1 className={styles.title_docs}>Plano de Ação</h1>
            {/* Inserir conteúdo futuramente */}
          </div>
        )}

        {expandido.parcerias && (
          <div className={styles.expansive_layout}>
            <h1 className={styles.title_docs}>Empresas Parceiras</h1>
            <RouletteBoardPartners />
          </div>
        )}
      </div>
    </div>
  );
};

export default TransparencyListExpansive;
