// RouletteBoard.js
import React, { useState } from 'react';
import CardRenderBoard from './CardRenderBoard';
import styles from './RouletteBoard.module.css'; // Importando o arquivo CSS
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const RouletteBoard = () => {
  const initialCards = [
    { funcao: 'PRESIDENTE', foto: require('../../img/colaboradores/diretoria/user_flavio_augusto.jpg'), cargo: 'Flávio Augusto de Oliveira' },
    { funcao: 'VICE – PRESIDENTE', foto: require('../../img/colaboradores/diretoria/user_carlos.jpeg'), cargo: 'Carlos Roberto Polaquini' },
    { funcao: '1ª SECRETÁRIA', foto: require('../../img/colaboradores/diretoria/user_cristiane.jpeg'), cargo: 'Cristiane Aparecida Maffei Faria' },
    { funcao: '2ª SECRETÁRIA', foto: require('../../img/colaboradores/diretoria/user_antonia.jpeg'), cargo: 'Antonia Pereira Cardoso de Oliveira' },
    { funcao: '1ª TESOUREIRA', foto: require('../../img/colaboradores/diretoria/user_eliane.jpeg'), cargo: 'Eliane Lopes' },
    { funcao: '2ª TESOUREIRA', foto: require('../../img/colaboradores/diretoria/user_antonio_carlos.jpg'), cargo: 'Antonio Carlos Morettin' },
    { funcao: 'CONSELHO FISCAL', foto: require('../../img/colaboradores/diretoria/user_marcia.jpg'), cargo: 'Marcia Regina Lui Balero' },
    { funcao: 'CONSELHO FISCAL', foto: require('../../img/colaboradores/diretoria/user_victor_hugo.jpg'), cargo: 'Victor Hugo Chiquetto Faria' },
    { funcao: 'CONSELHO FISCAL', foto: require('../../img/colaboradores/diretoria/user_tania.jpeg'), cargo: 'Tânia Mara Lui da Costa' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const visibleCards = initialCards.slice(currentIndex, currentIndex + 4);

  const moveLeft = () => {
    setCurrentIndex(prevIndex => {
      if (prevIndex === 0) {
        return initialCards.length - 4;
      }
      return prevIndex - 1;
    });
  };

  const moveRight = () => {
    setCurrentIndex(prevIndex => {
      if (prevIndex === initialCards.length - 4) {
        return 0;
      }
      return prevIndex + 1;
    });
  };

  return (
    <div className={styles.roulette}>
      <button className={styles.buttonLeft} onClick={moveLeft}><FaAngleLeft/></button>
      <CardRenderBoard cards={visibleCards} />
      <button className={styles.buttonRight} onClick={moveRight}><FaAngleRight/></button>
    </div>
  );
};

export default RouletteBoard;