//FuncionDocs_2022.js
import React from 'react';
import DocsListByYear from './DocsListByYear';

const FunctionDocs_2022 = () => {
  const documents = [
    { fileName: 'Declaração de Remuneração dos Dirigentes 2022.pdf' },
    { fileName: 'Declaração Quanto à Economicidade 2022.pdf' },
    { fileName: 'Demonstrativo Integral das Receitas e Despesas 2022.pdf' },
    { fileName: 'Plano de Trabalho PNAE 2022.pdf' },
    { fileName: 'Relação de Pagamentos 2022.pdf' },
    { fileName: 'Relação Nominal dos Dirigentes 2022.pdf' },
    { fileName: 'Relatorio de Execução Fisica do Objeto PNAE 2022.pdf' },
    { fileName: 'Termo de Colaboração PNAE 2022.pdf' },
  ];

  return <DocsListByYear year="2022" documents={documents} />;
};

export default FunctionDocs_2022;
