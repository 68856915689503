import styles from './Contact.module.css';
import {Link} from 'react-router-dom'
import { FaHome } from "react-icons/fa";


function Contact() {
    return(

        <div>
        <div className={styles.path_border}>
            <li className={styles.path}>
                <Link to="/"><FaHome/>Inicio</Link><span className={styles.path_cursor}> / Contato</span>
            </li>
        </div>

        <li className={styles.contact_background}>
            <div className={styles.contact_container}>
                <h1>
                    Contato
                </h1>
                 <p>
                    Você está na pagina de Contato.
                </p> 
                     
            </div>
        </li>
    </div>
    )

}

export default Contact