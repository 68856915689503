//home;js
import React from 'react';
import styles from './Home.module.css';
import { FaVideo, FaHandsHelping, FaBook, FaBullhorn, FaGlobeAmericas, FaClipboardList } from 'react-icons/fa';

function Home() {
  return (
    <div className={styles.home_background}>
      <div className={styles.home_container}>
        <header className={styles.header}>
          <h1 className={styles.title}>Bem-vindo ao Portal da Associação Beneficente Fonte Viva</h1>
          <p className={styles.subtitle}>Transparência, educação e compromisso com a comunidade</p>
        </header>

        <section className={styles.about_section}>
          <h2 className={styles.section_title}>
            <FaHandsHelping style={{ marginRight: '10px' }} />
            Sobre Nós
          </h2>
          <p className={styles.section_text}>
            É com muita alegria que recebemos sua visita em nosso site. A Associação Beneficente Fonte Viva é uma instituição dedicada ao acolhimento e à educação infantil de qualidade, com foco no desenvolvimento integral de crianças na primeiríssima infância.
          </p>
          <p className={styles.section_text}>
            Nosso portal tem como objetivo proporcionar maior proximidade e transparência nas nossas ações, apresentando de forma clara e acessível todas as iniciativas que realizamos para oferecer um futuro melhor às crianças que atendemos.
          </p>
          <p className={styles.section_text}>
            Acreditamos que a transparência e a prestação de contas são fundamentais para fortalecer a confiança da comunidade e de todos os nossos parceiros.
          </p>
          <p className={styles.section_text}>
            Através deste espaço, você poderá acompanhar o impacto das nossas atividades, conhecer nossos projetos e colaborar para que possamos continuar a fazer a diferença na vida de tantas crianças.
          </p>
          <p className={styles.section_text}>
            Sua participação é essencial para que nossa missão de promover educação, dignidade e oportunidades se torne cada vez mais forte. Agradecemos por sua visita e pelo seu apoio à nossa causa!
          </p>
        </section>

        <section className={styles.video_section}>
          <h2 className={styles.section_title}>
            <FaVideo style={{ marginRight: '10px' }} />
            Conheça Mais Sobre a Nossa Instituição
          </h2>
          <div className={styles.video_container}>
            <video className={styles.video} controls>
            <source src="/videos/Video_Creche_Fonte_Viva.mp4" type="video/mp4" />
              Seu navegador não suporta vídeo HTML5.
            </video>
          </div>
        </section>

        <section className={styles.objectives_section}>
          <h2 className={styles.section_title}>
            <FaClipboardList style={{ marginRight: '10px' }} />
            Objetivos do Portal
          </h2>
          <p className={styles.section_text}>
            O portal da Associação Beneficente Fonte Viva tem como principal objetivo oferecer uma plataforma digital transparente, acessível e informativa, que facilite a comunicação entre a instituição, seus parceiros, colaboradores e a comunidade em geral. Através deste espaço, buscamos:
          </p>
          <ul className={styles.objectives_list}>
            <li>
              <strong><FaClipboardList /> Transparência e Prestação de Contas:</strong> Garantir que todos os envolvidos possam acompanhar as ações da instituição, resultados de projetos e a utilização dos recursos, reforçando nosso compromisso com a ética e a responsabilidade.
            </li>
            <li>
              <strong><FaBullhorn /> Divulgação de Projetos e Atividades:</strong> Apresentar as iniciativas e programas realizados pela Associação, proporcionando uma visão clara do impacto social que estamos gerando.
            </li>
            <li>
              <strong><FaBook /> Facilitar o Acesso à Informação:</strong> Tornar mais fácil o acesso a informações sobre a missão, visão, valores e as formas de apoio à causa, além de fornecer atualizações sobre eventos e campanhas.
            </li>
            <li>
              <strong><FaHandsHelping /> Promover a Interação e Participação:</strong> Criar um canal direto para a comunidade, incentivando o engajamento de voluntários, doadores e parceiros para fortalecer o trabalho da instituição.
            </li>
            <li>
              <strong><FaGlobeAmericas /> Fortalecer o Compromisso Social:</strong> Reforçar a importância de ações colaborativas e solidárias, convidando todos a fazer parte do nosso movimento e contribuir para um futuro melhor para as crianças e famílias atendidas.
            </li>
          </ul>
          <p className={styles.section_text}>
            Nosso portal é um meio para estreitar laços, gerar confiança e garantir que todos os que acreditam em nossa causa possam acompanhar e contribuir para o sucesso de nossa missão.
          </p>
        </section>
      </div>
    </div>
  );
}

export default Home;
